import "./App.css";
import HomePage from "./Pages/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Products from "./Pages/Products";
import IntallationGuide from "./Pages/IntallationGuide";
import AboutUs from "./Pages/AboutUsPage";
import Gallery from "./Pages/Gallery";
import PopularChoices from "./Pages/PopularChoices";
import PhomiWorldwide from "./Pages/PhomiWorldwide";
import Catalog from "./Pages/Catalog";
import Events from "./Pages/Events";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/Gallery" element={<Gallery />} />
        <Route path="/products" element={<Products />} />
        <Route path="/popular-choices" element={<PopularChoices />} />
        <Route path="/IntallationGuide" element={<IntallationGuide />} />
        <Route path="/phomi-worldwide" element={<PhomiWorldwide />} />
        <Route path="/catalog" element={<Catalog />} />
        <Route path="/events" element={<Events />} />
        <Route path="/About-us" element={<AboutUs />} />
      </Routes>
    </>
  );
}

export default App;
