import React from "react";
import Collapse from "../../UI/Collapse";
import ourValueImg from "../../assests/images/about/ourValue.png";
import SectionHeader from "../../UI/SectionHeader";

const OurValues = () => {
  const values = [
    {
      title: "Sustainability",
      description:
        "Environmental responsibility is at the core of our values. We are dedicated to minimizing our ecological footprint by promoting sustainable practices throughout our manufacturing processes and product lifecycle.",
    },
    {
      title: "Innovation",
      description:
        "We embrace creativity and continually strive to bring cutting-edge, innovative wall covering materials to the market, setting new standards in design and functionality",
    },
    {
      title: "Quality Excellence",
      description:
        "Our dedicated team through their ethical principals and high level integrity is committed to delivering products & Installations of the highest quality, ensuring that our wall covering materials not only meet but exceed the expectations of our customers and partners",
    },
    {
      title: "Customer-Centric Focus",
      description:
        "Our customers are at the heart of everything we do. We prioritize their needs, providing exceptional service, and building lasting relationships based on trust, transparency, and reliability",
    },
    {
      title: "Collaboration",
      description:
        "We believe in the power of collaboration. By fostering partnerships with architects, designers, distributors, and other stakeholders, we aim to create synergies that drive mutual success",
    },
  ];
  return (
    <div className="ourValues ">
      <div className="container">
        <div className="valueContent row">
          <div className="collapeseItems mb-4  col-lg-7">
            <div className="header-holder mb-4">
              <div className="header ">
                <h1>Our Values</h1>
                <div className="line"></div>
              </div>
            </div>
            {values.map((value, index) => {
              return (
                <Collapse title={value.title} description={value.description} />
              );
            })}
          </div>
          <div className="col-lg-5">
            <img
              style={{ width: "100%" }}
              src={ourValueImg}
              alt="ourValueImg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurValues;
