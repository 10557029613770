import React from "react";
import overlay from "../../assests/images/what-overlay.png";
import WhatCard from "../../UI/WhatCard";
import what1 from "../../assests/images/what1.png";
import what2 from "../../assests/images/what2.png";
import what3 from "../../assests/images/what3.png";
import what4 from "../../assests/images/what4.png";
const WhatMake = (props) => {
  const data = [
    {
      img: what1,
      header: "Experienced",
      content:
        "Our experience of 25 years of building and making achievements in the world of development",
    },
    {
      img: what2,
      header: "Competitive Price",
      content:
        "The prices we offer you are very competitive without reducing the quality of the company's work in the slightest",
    },
    {
      img: what3,
      header: "On Time",
      content: "We prioritize the quality of our work and finish it on time",
    },
    {
      img: what4,
      header: "Best Materials",
      content:
        "The material determines the building itself so we recommend that you use the best & quality materials in its class.",
    },
  ];
  return (
    <section className="whatMake py-5">
      <img className="overlay" src={overlay} alt="" />
      <div className="container">
        <div className="header-holder mt-3 d-flex flex-wrap justify-content-evenly">
          <div className="header col-lg-3">
            <div className="line"></div>
            <h1>What Make Us Different?</h1>
          </div>
          <p className=" col-lg-7">
            Check out our best service you can possibly orders in building your
            company and don't forget to ask via our email or our customer
            service if you are interested in using our services
          </p>
        </div>
        <div className="row my-5">
          {data?.map((e, i) => {
            return <WhatCard key={i} {...e} />;
          })}
        </div>
      </div>
    </section>
  );
};

export default WhatMake;
