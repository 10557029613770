export const CataLogData = [
  {
    Sereis: "Coral Travertine ",
    TotalSizes: "1.0",
    SizeLevel1: "1200*600 (4.0)",
    $persqM1: "11.82",
    SizeLevel2: " ",
    $persqM2: " ",
    SizeLevel3: " ",
    $persqM3: " ",
    SizeLevel4: " ",
    $persqM4: " ",
    SizeLevel5: " ",
  },
  {
    Sereis: "Rome Travertine ",
    TotalSizes: "3.0",
    SizeLevel1: "1200*600 (4.0)",
    $persqM1: " ",
    SizeLevel2: "1200*600 (3.0)",
    $persqM2: " ",
    SizeLevel3: "2400*1200 (3.0)",
    $persqM3: " ",
    SizeLevel4: " ",
    $persqM4: " ",
    SizeLevel5: " ",
  },
  {
    Sereis: "Cloud Silk Travertine",
    TotalSizes: "1.0",
    SizeLevel1: "1200*600 (4.0)",
    $persqM1: "12.78",
    SizeLevel2: " ",
    $persqM2: " ",
    SizeLevel3: " ",
    $persqM3: " ",
    SizeLevel4: " ",
    $persqM4: " ",
    SizeLevel5: " ",
  },
  {
    Sereis: "Oceanic Travertine",
    TotalSizes: "3.0",
    SizeLevel1: "1200*600 (4.0)",
    $persqM1: " ",
    SizeLevel2: "1360*600 (3.0)",
    $persqM2: " ",
    SizeLevel3: "2800*1200 (3.0)",
    $persqM3: " ",
    SizeLevel4: " ",
    $persqM4: " ",
    SizeLevel5: " ",
  },
  {
    Sereis: "Rough Surface",
    TotalSizes: "2.0",
    SizeLevel1: "1200*600 (3.0)",
    $persqM1: " ",
    SizeLevel2: "2400*1200 (3.0)",
    $persqM2: " ",
    SizeLevel3: " ",
    $persqM3: " ",
    SizeLevel4: " ",
    $persqM4: " ",
    SizeLevel5: " ",
  },
  {
    Sereis: "Rough Surface (Rock look)",
    TotalSizes: "2.0",
    SizeLevel1: "1200*600 (3.0)",
    $persqM1: "11.09",
    SizeLevel2: "2400*1200 (3.0)",
    $persqM2: " ",
    SizeLevel3: " ",
    $persqM3: " ",
    SizeLevel4: " ",
    $persqM4: " ",
    SizeLevel5: " ",
  },

  {
    Sereis: "Marble 1",
    TotalSizes: "3.0",
    SizeLevel1: "1200*600 (4.0)",
    $persqM1: "11.09",
    SizeLevel2: "1200*600 (3.0)",
    $persqM2: " ",
    SizeLevel3: "2400*1200 (3.0)",
    $persqM3: " ",
    SizeLevel4: " ",
    $persqM4: " ",
    SizeLevel5: " ",
  },
  {
    Sereis: "Marble 2",
    TotalSizes: "3.0",
    SizeLevel1: "1200*600 (4.0)",
    $persqM1: "12.06",
    SizeLevel2: "1200*600 (3.0)",
    $persqM2: " ",
    SizeLevel3: "2400*1200 (3.0)",
    $persqM3: " ",
    SizeLevel4: " ",
    $persqM4: " ",
    SizeLevel5: " ",
  },
  {
    Sereis: "Polished Stone",
    TotalSizes: "1.0",
    SizeLevel1: "1200*600 (4.0)",
    $persqM1: "11.09",
    SizeLevel2: " ",
    $persqM2: " ",
    SizeLevel3: " ",
    $persqM3: " ",
    SizeLevel4: " ",
    $persqM4: " ",
    SizeLevel5: " ",
  },
  {
    Sereis: "Polish Concrete Wall",
    TotalSizes: "3.0",
    SizeLevel1: "1200*600 (4.0)",
    $persqM1: "11.09",
    SizeLevel2: "1200*600 (3.0)",
    $persqM2: " ",
    SizeLevel3: "2400*1200 (3.0)",
    $persqM3: " ",
    SizeLevel4: " ",
    $persqM4: " ",
    SizeLevel5: " ",
  },
  {
    Sereis: "Rusty Slab ",
    TotalSizes: "3.0",
    SizeLevel1: "1200*600 (4.0)",
    $persqM1: " ",
    SizeLevel2: "1200*600 (3.0)",
    $persqM2: " ",
    SizeLevel3: "2400*1200 (3.0)",
    $persqM3: " ",
    SizeLevel4: " ",
    $persqM4: " ",
    SizeLevel5: " ",
  },
  {
    Sereis: "Granite Flat",
    TotalSizes: "3.0",
    SizeLevel1: "1200*600 (3.0)",
    $persqM1: "11.09",
    SizeLevel2: "1200*600 (3.0)",
    $persqM2: " ",
    SizeLevel3: "2400*1200 (3.0)",
    $persqM3: " ",
    SizeLevel4: " ",
    $persqM4: " ",
    SizeLevel5: " ",
  },
  {
    Sereis: "Mount Celestial",
    TotalSizes: "4.0",
    SizeLevel1: "1200*600 (3.0)",
    $persqM1: " ",
    SizeLevel2: "2400*1200 (3.0)",
    $persqM2: " ",
    SizeLevel3: "2700*1200 (3.0)",
    $persqM3: " ",
    SizeLevel4: "60000*1200 (3.0)",
    $persqM4: " ",
    SizeLevel5: " ",
  },
  {
    Sereis: "Skyline",
    TotalSizes: "3.0",
    SizeLevel1: "1200*600 (4.0)",
    $persqM1: " ",
    SizeLevel2: "1200*600 (3.0)",
    $persqM2: " ",
    SizeLevel3: "2400*1200 (3.0)",
    $persqM3: " ",
    SizeLevel4: " ",
    $persqM4: " ",
    SizeLevel5: " ",
  },
  {
    Sereis: "Sandstone",
    TotalSizes: "3.0",
    SizeLevel1: "1200*600 (4.0)",
    $persqM1: "13.97",
    SizeLevel2: "1200*600 (3.0)",
    $persqM2: " ",
    SizeLevel3: "2400*1200 (3.0)",
    $persqM3: " ",
    SizeLevel4: " ",
    $persqM4: " ",
    SizeLevel5: " ",
  },
  {
    Sereis: "Rammed Earth Wall",
    TotalSizes: "2.0",
    SizeLevel1: "1200*600 (3.0)",
    $persqM1: "25.92",
    SizeLevel2: "2400*1200 (3.0)",
    $persqM2: " ",
    SizeLevel3: " ",
    $persqM3: " ",
    SizeLevel4: " ",
    $persqM4: " ",
    SizeLevel5: " ",
  },
  {
    Sereis: "Crossard Mashroom Stone",
    TotalSizes: "2.0",
    SizeLevel1: "800*400 (3.0)",
    $persqM1: " ",
    SizeLevel2: "800*800 (3.0)",
    $persqM2: " ",
    SizeLevel3: " ",
    $persqM3: " ",
    SizeLevel4: " ",
    $persqM4: " ",
    SizeLevel5: " ",
  },
  {
    Sereis: "Devine Mashroom Stone ",
    TotalSizes: "5.0",
    SizeLevel1: "800*400 (3.0)",
    $persqM1: "25.92",
    SizeLevel2: "600*600 (3.0)",
    $persqM2: " ",
    SizeLevel3: "1200*600 (3.0)",
    $persqM3: " ",
    SizeLevel4: "1200*1200 (3.0)",
    $persqM4: " ",
    SizeLevel5: "2400*1200 (3.0)",
  },
  {
    Sereis: "Oblique Mashroom",
    TotalSizes: "2.0",
    SizeLevel1: "1200*600 (3.0)",
    $persqM1: "16.07",
    SizeLevel2: "1200*1200 (3.0)",
    $persqM2: " ",
    SizeLevel3: " ",
    $persqM3: " ",
    SizeLevel4: " ",
    $persqM4: " ",
    SizeLevel5: " ",
  },
  {
    Sereis: "Concrete Pouring Slab",
    TotalSizes: "2.0",
    SizeLevel1: "1400*600 (3.0)",
    $persqM1: "13.81",
    SizeLevel2: "2800*1200 (3.0)",
    $persqM2: " ",
    SizeLevel3: " ",
    $persqM3: " ",
    SizeLevel4: " ",
    $persqM4: " ",
    SizeLevel5: " ",
  },
  {
    Sereis: "Arcute Rock",
    TotalSizes: "2.0",
    SizeLevel1: "1400*600 (3.0)",
    $persqM1: " ",
    SizeLevel2: "2800*1200 (3.0)",
    $persqM2: " ",
    SizeLevel3: " ",
    $persqM3: " ",
    SizeLevel4: " ",
    $persqM4: " ",
    SizeLevel5: " ",
  },
  {
    Sereis: "Infinity",
    TotalSizes: "2.0",
    SizeLevel1: "600*300 (3.0)",
    $persqM1: " ",
    SizeLevel2: "1200*600 (3.0)",
    $persqM2: " ",
    SizeLevel3: " ",
    $persqM3: " ",
    SizeLevel4: " ",
    $persqM4: " ",
    SizeLevel5: " ",
  },
  {
    Sereis: "35 Pieces Stone",
    TotalSizes: "1.0",
    SizeLevel1: "1200*600 (3.0)",
    $persqM1: " ",
    SizeLevel2: " ",
    $persqM2: " ",
    SizeLevel3: " ",
    $persqM3: " ",
    SizeLevel4: " ",
    $persqM4: " ",
    SizeLevel5: " ",
  },
  {
    Sereis: "Chiseled Stone",
    TotalSizes: "2.0",
    SizeLevel1: "1200*600 (3.0)",
    $persqM1: " ",
    SizeLevel2: "2400*1200 (3.0)",
    $persqM2: " ",
    SizeLevel3: " ",
    $persqM3: " ",
    SizeLevel4: " ",
    $persqM4: " ",
    SizeLevel5: " ",
  },
  {
    Sereis: "Oman Linear Stone",
    TotalSizes: "3.0",
    SizeLevel1: "1200*600 (4.0)",
    $persqM1: " ",
    SizeLevel2: "1350*600 (3.0)",
    $persqM2: " ",
    SizeLevel3: "2700*1200 (3.0)",
    $persqM3: " ",
    SizeLevel4: " ",
    $persqM4: " ",
    SizeLevel5: " ",
  },
  {
    Sereis: "Stone Ridged",
    TotalSizes: "2.0",
    SizeLevel1: "1200*600 (3.0)",
    $persqM1: "12.06",
    SizeLevel2: "2400*1200 (3.0)",
    $persqM2: " ",
    SizeLevel3: " ",
    $persqM3: " ",
    SizeLevel4: " ",
    $persqM4: " ",
    SizeLevel5: " ",
  },
  {
    Sereis: "Bush Hammered",
    TotalSizes: "3.0",
    SizeLevel1: "900*600 (3.0)",
    $persqM1: " ",
    SizeLevel2: "1380*600 (3.0)",
    $persqM2: " ",
    SizeLevel3: "2760*1200 (3.0)",
    $persqM3: " ",
    SizeLevel4: " ",
    $persqM4: " ",
    SizeLevel5: " ",
  },
  {
    Sereis: "Butterfly Stone",
    TotalSizes: "1.0",
    SizeLevel1: "2600*1200 (3.0)",
    $persqM1: " ",
    SizeLevel2: " ",
    $persqM2: " ",
    SizeLevel3: " ",
    $persqM3: " ",
    SizeLevel4: " ",
    $persqM4: " ",
    SizeLevel5: " ",
  },
  {
    Sereis: "3D Papel",
    TotalSizes: "2.0",
    SizeLevel1: "1200*600 (3.0)",
    $persqM1: " ",
    SizeLevel2: "2400*1200 (3.0)",
    $persqM2: " ",
    SizeLevel3: " ",
    $persqM3: " ",
    SizeLevel4: " ",
    $persqM4: " ",
    SizeLevel5: " ",
  },
  {
    Sereis: "Stackle Square",
    TotalSizes: "1.0",
    SizeLevel1: "1200*600 (4)",
    $persqM1: " ",
    SizeLevel2: " ",
    $persqM2: " ",
    SizeLevel3: " ",
    $persqM3: " ",
    SizeLevel4: " ",
    $persqM4: " ",
    SizeLevel5: " ",
  },
  {
    Sereis: "Rockface Stone",
    TotalSizes: "3.0",
    SizeLevel1: "1200*600 (3.0)",
    $persqM1: "11.71",
    SizeLevel2: "1200*1200 (3.0)",
    $persqM2: " ",
    SizeLevel3: "2400*1200 (3.0)",
    $persqM3: " ",
    SizeLevel4: " ",
    $persqM4: " ",
    SizeLevel5: " ",
  },
  {
    Sereis: "Spliced Wood",
    TotalSizes: "2.0",
    SizeLevel1: "1400*600 (3.0)",
    $persqM1: " ",
    SizeLevel2: "2800*1200 (3.0)",
    $persqM2: " ",
    SizeLevel3: " ",
    $persqM3: " ",
    SizeLevel4: " ",
    $persqM4: " ",
    SizeLevel5: " ",
  },
  {
    Sereis: "Poly Wood",
    TotalSizes: "3.0",
    SizeLevel1: "1350*300 (3.0)",
    $persqM1: " ",
    SizeLevel2: "2700*300 (3.0)",
    $persqM2: " ",
    SizeLevel3: "2700*1200 (3.0)",
    $persqM3: " ",
    SizeLevel4: " ",
    $persqM4: " ",
    SizeLevel5: " ",
  },
  {
    Sereis: "Original Wood",
    TotalSizes: "3.0",
    SizeLevel1: "1350*300 (3.0)",
    $persqM1: "11.71",
    SizeLevel2: "2700*300 (3.0)",
    $persqM2: " ",
    SizeLevel3: "2700*1200 (3.0)",
    $persqM3: " ",
    SizeLevel4: " ",
    $persqM4: " ",
    SizeLevel5: " ",
  },
  {
    Sereis: "Swatooth Wood",
    TotalSizes: "3.0",
    SizeLevel1: "1350*300 (3.0)",
    $persqM1: " ",
    SizeLevel2: "2700*300 (3.0)",
    $persqM2: " ",
    SizeLevel3: "2700*1200 (3.0)",
    $persqM3: " ",
    SizeLevel4: " ",
    $persqM4: " ",
    SizeLevel5: " ",
  },
  {
    Sereis: "Polished Wood",
    TotalSizes: "1.0",
    SizeLevel1: "1200*600 (4)",
    $persqM1: " ",
    SizeLevel2: " ",
    $persqM2: " ",
    SizeLevel3: " ",
    $persqM3: " ",
    SizeLevel4: " ",
    $persqM4: " ",
    SizeLevel5: " ",
  },
  {
    Sereis: "W Weaving ",
    TotalSizes: "2.0",
    SizeLevel1: "1350*300 (3.0)",
    $persqM1: " ",
    SizeLevel2: "2700*1200 (3.0)",
    $persqM2: " ",
    SizeLevel3: " ",
    $persqM3: " ",
    SizeLevel4: " ",
    $persqM4: " ",
    SizeLevel5: " ",
  },
  {
    Sereis: "Ocean Flower Weaving",
    TotalSizes: "3.0",
    SizeLevel1: "1200*600 (3.0)",
    $persqM1: " ",
    SizeLevel2: "1200*1200 (3.0)",
    $persqM2: " ",
    SizeLevel3: "2400*1200 (3.0)",
    $persqM3: " ",
    SizeLevel4: " ",
    $persqM4: " ",
    SizeLevel5: " ",
  },
  {
    Sereis: "W Herringbone",
    TotalSizes: "3.0",
    SizeLevel1: "1200*600 (3.0)",
    $persqM1: "16.52",
    SizeLevel2: "1200*1200 (3.0)",
    $persqM2: " ",
    SizeLevel3: "2400*1200 (3.0)",
    $persqM3: " ",
    SizeLevel4: " ",
    $persqM4: " ",
    SizeLevel5: " ",
  },
  {
    Sereis: "Rope Wave A",
    TotalSizes: "2.0",
    SizeLevel1: "1350*600 (3.0)",
    $persqM1: "13.6",
    SizeLevel2: "2700*1200 (3.0)",
    $persqM2: " ",
    SizeLevel3: " ",
    $persqM3: " ",
    SizeLevel4: " ",
    $persqM4: " ",
    SizeLevel5: " ",
  },
  {
    Sereis: "Rope Wave B",
    TotalSizes: "2.0",
    SizeLevel1: "1350*600 (3.0)",
    $persqM1: "14.04",
    SizeLevel2: "2700*1200 (3.0)",
    $persqM2: " ",
    SizeLevel3: " ",
    $persqM3: " ",
    SizeLevel4: " ",
    $persqM4: " ",
    SizeLevel5: " ",
  },
  {
    Sereis: "Rattan Mat A",
    TotalSizes: "2.0",
    SizeLevel1: "1350*600 (3.0)",
    $persqM1: "14.04",
    SizeLevel2: "2700*1200 (3.0)",
    $persqM2: " ",
    SizeLevel3: " ",
    $persqM3: " ",
    SizeLevel4: " ",
    $persqM4: " ",
    SizeLevel5: " ",
  },
  {
    Sereis: "Rattan Mat B",
    TotalSizes: "2.0",
    SizeLevel1: "1200*600 (3.0)",
    $persqM1: "15.41",
    SizeLevel2: "2400*1200 (3.0)",
    $persqM2: " ",
    SizeLevel3: " ",
    $persqM3: " ",
    SizeLevel4: " ",
    $persqM4: " ",
    SizeLevel5: " ",
  },
  {
    Sereis: "Bamboo Straw A",
    TotalSizes: "2.0",
    SizeLevel1: "1350*600 (3.0)",
    $persqM1: " ",
    SizeLevel2: "2700*1200 (3.0)",
    $persqM2: " ",
    SizeLevel3: " ",
    $persqM3: " ",
    SizeLevel4: " ",
    $persqM4: " ",
    SizeLevel5: " ",
  },
  {
    Sereis: "Rattan Knit A",
    TotalSizes: "2.0",
    SizeLevel1: "1350*600 (3.0)",
    $persqM1: " ",
    SizeLevel2: "2700*1200 (3.0)",
    $persqM2: " ",
    SizeLevel3: " ",
    $persqM3: " ",
    SizeLevel4: " ",
    $persqM4: " ",
    SizeLevel5: " ",
  },
];
