import React, { useState } from "react";
import SectionHeader from "../../UI/SectionHeader";

import { CataLogData } from "../../assests/db/catalog";
import PDFViewer from "../../UI/PDFViewer";
import catalogIcon from "../../assests/images/catalog.png";

export default function Catalog() {
  const [showCatelog1, setShowCatelog1] = useState(false);
  return (
    <section className="catalog-product   py-4 my-5">
      <SectionHeader header={" Catalog 2024"} />
      <h1
        onClick={() => {
          setShowCatelog1(true);
        }}
        className="text-center show-catalog-btn"
      >
        Show Catalog
        <img src={catalogIcon} alt="" />
      </h1>
      <PDFViewer
        state={showCatelog1}
        setState={setShowCatelog1}
        url={"https://phomi.ca/catalog/2024CATALOG.pdf"}
      />
      )
      <div className="my-2">
        {" "}
        <div className=" col-11 m-auto scrollable-div overflow-scroll">
          <table className="catalog-table">
            <thead>
              <tr className="text-center">
                <th></th>
                <th>Series Name</th>
                <th> Total Sizes</th>
                <th>Size & Level</th>
                <th>$ per sqM</th>
                <th>Size & Level</th>
                <th>$ per sqM</th>
                <th>Size & Level</th>
                <th>$ per sqM</th>
                <th>Size & Level</th>
                <th>$ per sqM</th>
                <th>Size & Level</th>
                <th>$ per sqM</th>
              </tr>
            </thead>
            <tbody>
              {CataLogData.map((e, i) => {
                return (
                  <tr className="text-center">
                    <td>{i + 1}</td>
                    <td>{e.Sereis} </td>
                    <td>{e.TotalSizes}</td>
                    <td>{e.SizeLevel1}</td>
                    <td>{e.$persqM1}</td>
                    <td>{e.SizeLevel2}</td>
                    <td>{e.$persqM2}</td>
                    <td>{e.SizeLevel3}</td>
                    <td>{e.$persqM3}</td>
                    <td>{e.SizeLevel4}</td>
                    <td>{e.$persqM4}</td>
                    <td>{e.SizeLevel5}</td>
                    <td> </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
}
