import React, { useState } from 'react';

import ProductCard from '../../UI/ProductCard';
import { products } from '../../assests/db/products';

const ProductTabs = () => {
  const [activeTab, setActiveTab] = useState('all');

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  return (
    <div className='container'>
      <div className='tab-buttons'>
        <button
          onClick={() => handleTabClick('all')}
          className={activeTab === 'all' ? 'active' : ''}
        >
          All
        </button>
        <button
          onClick={() => handleTabClick('bricks')}
          className={activeTab === 'bricks' ? 'active' : ''}
        >
          Bricks
        </button>
        <button
          onClick={() => handleTabClick('stones')}
          className={activeTab === 'stones' ? 'active' : ''}
        >
          Stones
        </button>
        <button
          onClick={() => handleTabClick('wood')}
          className={activeTab === 'wood' ? 'active' : ''}
        >
          Wood
        </button>
        <button
          onClick={() => handleTabClick('leathers')}
          className={activeTab === 'leathers' ? 'active' : ''}
        >
          Leathers
        </button>
        <button
          onClick={() => handleTabClick('others')}
          className={activeTab === 'others' ? 'active' : ''}
        >
          Others
        </button>
      </div>

      <div className='tab-content'>
        {activeTab === 'all' && (
          <div className='row my-5'>
            {products.map((product, i) => (
              <ProductCard key={i} {...product} />
            ))}
          </div>
        )}
        {activeTab === 'bricks' && (
          <div className='row my-5'>
            {products
              ?.filter((product) => product.type === activeTab)
              .map((product, i) => (
                <ProductCard key={i} {...product} />
              ))}
          </div>
        )}
        {activeTab === 'stones' && (
          <div className='row my-5'>
            {products
              ?.filter((product) => product.type === activeTab)
              .map((product, i) => (
                <ProductCard key={i} {...product} />
              ))}
          </div>
        )}
        {activeTab === 'wood' && (
          <div className='row my-5'>
            {products
              ?.filter((product) => product.type === activeTab)
              .map((product, i) => (
                <ProductCard key={i} {...product} />
              ))}
          </div>
        )}
        {activeTab === 'leathers' && (
          <div className='row my-5'>
            {products
              ?.filter((product) => product.type === activeTab)
              .map((product, i) => (
                <ProductCard key={i} {...product} />
              ))}
          </div>
        )}
        {activeTab === 'others' && (
          <div className='row my-5'>
            {products
              ?.filter((product) => product.type === activeTab)
              .map((product, i) => (
                <ProductCard key={i} {...product} />
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductTabs;
