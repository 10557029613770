import React from "react";
import SectionHeader from "../../UI/SectionHeader";
import ProductCard from "../../UI/ProductCard";
import img2 from "../../assests/images/product2.png";
import img4 from "../../assests/images/product4.png";
import s10 from "../../assests/images/products/Slate Stone.jpg";

import w1 from "../../assests/images/products/Original Wood.jpg";

export default function OurProducts() {
  const data = [
    {
      img: img2,
      header: "Big Rattan Weaves",
      info: ["size 1800 x 600 mm thickness 5 mm"],
    },
    {
      img: img4,
      header: "Crossard Mushroom Stones",
      info: ["Size 800 x 400 mm"],
    },
    {
      type: "stones",
      img: s10,
      header: "Slate Stone",
      info: ["1150  x 575 mm", "575 x 286 mm"],
    },
    {
      type: "wood",
      img: w1,
      header: "Original Wood",
      info: ["900  x 180 mm"],
    },
  ];
  return (
    <section className="our-products py-5">
      <div className="container">
        <SectionHeader header={"our products"} />
        <div className="row my-5">
          {data?.map((e, i) => {
            return <ProductCard key={i} {...e} />;
          })}
        </div>
        <div className="view-more">
          <a href="/products">View More</a>
        </div>
      </div>
    </section>
  );
}
