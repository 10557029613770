// all bricks images
import b1 from "../images/products/G-Series Facing Bricks.jpg";
import b2 from "../images/products/K-Series Facing Bricks.jpg";
import b3 from "../images/products/R-Series Facing Bricks.jpeg";
import b4 from "../images/products/A-Series Facing Bricks.jpg";
// all stones images
import s1 from "../images/products/Crossard Mushroom Stones.jpg";
import s2 from "../images/products/Cut Stone.jpg";
import s3 from "../images/products/Devine Mushroom Stones.jpg";
import s4 from "../images/products/Granite.jpg";
import s5 from "../images/products/Infiniti Stone.jpg";
import s6 from "../images/products/Oasis Stone.jpeg";
import s7 from "../images/products/Oceanic Travertine.jpeg";
import s8 from "../images/products/Sand Stone.jpg";
import s9 from "../images/products/Travertine.jpg";
import s10 from "../images/products/Slate Stone.jpg";
// all wood images
import w1 from "../images/products/Original Wood.jpg";
// all leathers images
import L1 from "../images/products/Coarse Leather.jpg";
import L2 from "../images/products/Croco.jpg";
import L3 from "../images/products/Elephantiasi.jpg";
// all others images
import other1 from "../images/products/Big Rattan Weaves.jpeg";
import other2 from "../images/products/Cube Cut.jpg";
import other3 from "../images/products/Small Rattan Weaves.jpeg";
import other4 from "../images/products/Stackle Square.jpg";
import other5 from "../images/products/Twisting Ropes.jpeg";
import other6 from "../images/products/W Weaving.jpg";

export const products = [
  {
    type: "bricks",
    img: b1,
    header: "G-Series Facing Bricks",
    info: ["230 x 58 mm", "thickness 3 mm"],
  },
  {
    type: "bricks",
    img: b2,
    header: "K SERIES FACING BRICKS",
    info: ["225 x 60 mm", "thickness 3 – 4 mm"],
  },
  {
    type: "bricks",
    img: b3,
    header: "R Series Facing Bricks",
    info: ["222  x 62 mm", "thickness 3 mm"],
  },
  {
    type: "bricks",
    img: b4,
    header: "A Series Facing Bricks.jpg",
    info: ["240  x 60 mm", "thickness 3 mm"],
  },
  {
    type: "stones",
    img: s1,
    header: "Crossard Mushroom Stones",
    info: ["800  x 400 mm"],
  },
  {
    type: "stones",
    img: s2,
    header: "Cut Stone",
    info: ["1200  x 600 mm"],
  },
  {
    type: "stones",
    img: s3,
    header: "Devine Mushroom Stones",
    info: ["2000  x 1000 mm", "600 x 300 mm"],
  },
  {
    type: "stones",
    img: s4,
    header: "Granite",
    info: ["1590  x 580 mm"],
  },
  {
    type: "stones",
    img: s5,
    header: "Infiniti Stone",
    info: ["590  x 300 mm"],
  },
  {
    type: "stones",
    img: s6,
    header: "Oasis Stone",
    info: ["1800  x 600 mm", "900 x 600 mm"],
  },
  {
    type: "stones",
    img: s7,
    header: "Oceanic Travertine",
    info: ["2800  x 1220 mm", "thickness 5 mm"],
  },
  {
    type: "stones",
    img: s8,
    header: "Sand Stone",
    info: ["1200  x 600 mm", "575 x 286 mm"],
  },
  {
    type: "stones",
    img: s9,
    header: "Travertine",
    info: ["1200  x 600 mm", "2400 x 1200 mm", "600 x 300 mm"],
  },
  {
    type: "stones",
    img: s10,
    header: "Slate Stone",
    info: ["1150  x 575 mm", "575 x 286 mm"],
  },
  {
    type: "wood",
    img: w1,
    header: "Original Wood",
    info: ["900  x 180 mm"],
  },
  {
    type: "leathers",
    img: L1,
    header: "Coarse Leather",
    info: ["1500  x 600 mm", "300 x 600"],
  },

  {
    type: "others",
    img: other1,
    header: "Big Rattan Weaves",
    info: ["1800  x 600 mm", "thickness 5 mm"],
  },
  {
    type: "others",
    img: other2,
    header: "Cube Cut",
    info: ["300  x 300 mm"],
  },
  {
    type: "others",
    img: other3,
    header: "Small Rattan Weaves",
    info: ["1800  x 600 mm", "thickness 5 mm"],
  },
  {
    type: "others",
    img: other4,
    header: "Stackle Square",
    info: ["300  x 300 mm"],
  },
  {
    type: "others",
    img: other5,
    header: "Twisting Ropes",
    info: ["1800  x 600 mm", "thickness 5 mm"],
  },
  {
    type: "others",
    img: other6,
    header: "W Weaving",
    info: ["1350  x 600 mm", "2700  x 1200 mm"],
  },
];
