import React from 'react';

export default function ProductCard(props) {
  return (
    <div
      className={`${
        !props.noImg ? 'col-lg-3 col-md-6 col-12' : 'col-lg-4 col-md-6 col-12'
      } mb-5`}
    >
      <div className={`product-card ${props.noImg ? 'hover' : ''}`}>
        {!props.noImg && (
          <div className='img-holder'>
            <img src={props.img} alt='' />
          </div>
        )}
        <h1 className='header'>{props.header}</h1>
        <div className='line'></div>
        {props.info?.map((e, i) => {
          return (
            <p className='info' key={i}>
              {e}
            </p>
          );
        })}
      </div>
    </div>
  );
}
