import React from "react";
import facebook from "../assests/images/facebook.png";
import dribble from "../assests/images/dribble.png";
import instagram from "../assests/images/instagram.png";
import linkedin from "../assests/images/linkedin.png";
const Footer = () => {
  return (
    <div className="container">
      <footer className="py-3 my-4">
        <ul className="nav justify-content-center border-bottom pb-3 mb-3">
          <li className="nav-item">
            <a href="/" className="nav-link px-2 text-body-secondary">
              Home
            </a>
          </li>
          <li className="nav-item">
            <a href="/Gallery" className="nav-link px-2 text-body-secondary">
              Gallery
            </a>
          </li>
          <li className="nav-item">
            <a href="/Products" className="nav-link px-2 text-body-secondary">
              Products
            </a>
          </li>
          <li className="nav-item">
            <a
              href="/phomi-worldwide"
              className="nav-link px-2 text-body-secondary"
            >
              Phomi Worldwide
            </a>
          </li>
          <li className="nav-item">
            <a
              href="/IntallationGuide"
              className="nav-link px-2 text-body-secondary"
            >
              Installation Guide
            </a>
          </li>
          <li className="nav-item">
            <a
              href="https://www.calameo.com/read/0076415187974609ef8ec"
              className="nav-link px-2 text-body-secondary"
            >
              Catalog
            </a>
          </li>
          <li className="nav-item">
            <a href="/events" className="nav-link px-2 text-body-secondary">
              Shows & Events
            </a>
          </li>
          <li className="nav-item">
            <a href="/About-us" className="nav-link px-2 text-body-secondary">
              About Us
            </a>
          </li>
        </ul>
        <div className="d-flex gap-5 justify-content-center border-bottom pb-3 mb-3">
          <a target="_blank" href="https://www.facebook.com/phomi.ca/">
            <img src={facebook} alt="" />
          </a>

          <a target="_blank" href="https://www.instagram.com/phomi.ca/">
            <img src={instagram} alt="" />
          </a>
          <a target="_blank" href="https://www.linkedin.com/company/phomi-ca/">
            <img src={linkedin} alt="" />
          </a>
        </div>
        <p className="text-center text-body-secondary">
          © 2023 Phomi Company, Inc
        </p>
      </footer>
    </div>
  );
};

export default Footer;
