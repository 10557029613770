import React from "react";
import emailIcon from "../assests/images/emailIcon.png";
import candaFlag from "../assests/images/canda flag.svg";
import phoneIcon from "../assests/images/phone.png";

import whatsIcon from "../assests/images/whatsicon.png";
const TopHeader = (props) => {
  return (
    <div className="top-header">
      <div className="container  d-flex flex-wrap justify-content-between py-3">
        <div className="d-flex align-items-center">
          <div>
            {" "}
            <img src={emailIcon} alt="" />
          </div>
          <a
            style={{ color: "#2e2e2e" }}
            href="mailto:info@phomi.ca"
            className="mb-0 mx-2 "
          >
            <span style={{ fontWeight: "700", marginRight: "5px" }}>
              Email us at :{" "}
            </span>
            info@phomi.ca
          </a>
        </div>
        <div className="right-side align-items-center d-flex">
          <div className="language-holder">
            <img src={candaFlag} alt="" />
          </div>
          <a
            href="https://api.whatsapp.com/send?phone=15488887070&text=hello phomi canda i want to contact with you

"
            className="phone-holder  d-flex"
          >
            <div>
              <img src={whatsIcon} alt="" />
            </div>
            <p className="mb-0">Message Us</p>
          </a>
          <a href="tel:+15488887070" className="phone-holder mx-3 d-flex">
            <div>
              <img src={phoneIcon} alt="" />
            </div>
            <p className="mb-0">5488887070</p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
